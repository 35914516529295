import * as React from "react";
import { Link, graphql } from "gatsby";
import {
  Layout,
  SEO,
  CJournalJumbotron,
  CBreadCrumb,
  CJournalLead,
  CProfileMedia,
  CJournalText,
  CWideImgMedia,
  CFloatingImg,
  CBtnList,
  LWrap,
  CQuoteBox,
  CSingleImg,
  CChapterHeading,
  CLabelTextList,
  CJournalMedia,
  CMedia,
  CTileImg,
} from "../../../components/_index";

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  console.log(data.markdownRemark);
  return (
    <Layout isKv={false}>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CBreadCrumb
        data={{
          parent: [
            {
              label: "pickup",
              path: "/pickup/",
            },
          ],
          current: {
            label: frontmatter?.title.includes("-")
              ? frontmatter?.title.split("-")[0]
              : frontmatter?.title,
          },
        }}
      />
      <CJournalJumbotron
        data={{
          date: "2023年08月31日",
          label: "ザ ロイヤルパーク キャンバス 福岡中洲",
          title: (
            <>
              人でつながり、人がつながる。
              <br />ザ ロイヤルパーク キャンバス 福岡中洲がSDGsで目指すもの
            </>
          ),
          img: {
            src: "/assets/images/journal/article08/kv.jpg",
          },
        }}
      />
      <div className="l_sect u_pt0">
        <LWrap size="small">
          <p></p>
          <CJournalLead
            lead={
              <>
                九州の玄関口と言われる福岡。そんな福岡随一の繁華街中洲に、2023年8月新たなホテルがオープンした。その名も、ザ
                ロイヤルパーク キャンバス
                福岡中洲。「九州の自然に癒される」というコンセプトのもと、随所にSDGsを意識した体験が散りばめられている。今回はホテルの開業までさまざまなプロジェクトに取り組んできた、総支配人の山本氏と運営推進部の松本氏にこれまでの取り組みについて、その想いを聞いた。
              </>
            }
          />
        </LWrap>
      </div>

      <section className="u_mbLarge">
        <CChapterHeading
          data={{
            label: {
              en: "CHAPTER",
              number: "01",
            },
            title: (
              <>
                <span>キーワードは“人”、</span>
                <span>ホテルが取り組む新たなSDGsアプローチ</span>
              </>
            ),
          }}
        />
        <LWrap size="small">
          <CQuoteBox>
            <p>
              ―ホテルのコンセプト「九州の自然に癒される」について教えてください。
            </p>
          </CQuoteBox>
          <CLabelTextList
            data={[
              {
                label: "松本",
                text: (
                  <>
                    福岡の中心地に新たにホテルを開業するということで、コンセプトについては多くの議論がありました。福岡を代表する博多祇園山笠や博多織などの伝統的なモチーフをデザインに落とし込んだホテルが多い中、何か違ったアプローチができないかと考えたのが発端のようです。
                  </>
                ),
              },
              {
                label: "山本",
                text: (
                  <>
                    福岡はアジアの玄関口という都市的な一面もありますし、九州全体で見ると豊かな自然という資源もあります。そんな視点から「都会のオアシス」という新しいコンセプトのホテルを生み出せるのではないかと考え、決まりました。地元の方々にとってもパワースポットになればいいなと。
                  </>
                ),
              },
            ]}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article08/img_chapter01_01.jpg",
              alt: "",
            }}
            caption={
              <>
                九州の自然に包まれ、自然の魅力を感じ、癒されるアーバンリゾート。
              </>
            }
          />
          <CLabelTextList
            data={[
              {
                label: "松本",
                text: (
                  <>
                    とはいえ、外装や内装はアイデアがたくさん出てくるのですが、自然というワードをソフト面にどう落とし込んでいくのかについてはいろいろと悩まれていましたよね。
                  </>
                ),
              },
              {
                label: "山本",
                text: (
                  <>
                    ストーリーが重要だと思っていたので、いかにコンセプトを体現するような物語を体験やサービスの中に落とし込めるのか、手探りでしたね。
                  </>
                ),
              },
            ]}
          />
          <CProfileMedia
            reverse={true}
            image={{
              img: {
                src: "/assets/images/journal/article08/img_chapter01_02.jpg",
              },
              imgSp: {
                src: "/assets/images/journal/article08/img_chapter01_02__sp.png",
              },
            }}
            alt={"ザ ロイヤルパーク キャンバス 福岡中洲 総支配人 山本 正一 氏"}
            company={<>ザ ロイヤルパーク キャンバス 福岡中洲</>}
            officer={<>総支配人</>}
            name={<>山本 正一</>}
            honor={"氏"}
            text={
              <>
                ロイヤルパークホテルに入社後、サービス業務を経験。2021年に「ザ
                ロイヤルパークホテル
                福岡」の総支配人を経て、2022年9月より開業推進部にて新規ホテルのプロジェクトに従事。「ザ
                ロイヤルパーク キャンバス 福岡中洲」開業後は総支配人に就任。
              </>
            }
          />
          <CLabelTextList
            data={[
              {
                label: "松本",
                text: (
                  <>
                    あとはSDGsというところも大きな柱。ホテルができる取り組みというとパッと思い浮かぶのは環境に配慮したアメニティの採用などですよね。もちろんそれにも取り組んでいきたいのですが、何か新しいSDGsへのアプローチができないかと。
                  </>
                ),
              },
              {
                label: "山本",
                text: (
                  <>
                    そんな話をしながらSDGs17の目標を見ていたら、そこに書かれている内容の半分ぐらいが“人”に関するものだということに気づいたんです。たとえば、ジェンダー平等や働きがいなど、キーワードは“人”だなと。誰一人取り残さないという原則にも共感する部分がありました。
                  </>
                ),
              },
              {
                label: "松本",
                text: (
                  <>
                    そもそもホテルは、人が集い、コミュニケーションをとる場ですから、そういった考えともマッチしましたよね。
                  </>
                ),
              },
            ]}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article08/img_chapter01_03.jpg",
              alt: "SDGs",
            }}
          />
        </LWrap>
      </section>

      <section className="u_mbLarge">
        <CChapterHeading
          data={{
            label: {
              en: "CHAPTER",
              number: "02",
            },
            title: (
              <>
                <span>人が集い、</span>
                <span>出会う場からきっかけが生まれる</span>
              </>
            ),
          }}
        />
        <LWrap size="small">
          <CQuoteBox>
            <p>
              ―コンセプトやSDGsへの取り組みなど軸が定まってからは、どのようにパートナーを探していったのでしょうか。
            </p>
          </CQuoteBox>
          <CLabelTextList
            data={[
              {
                label: "松本",
                text: (
                  <>
                    大切にしていたのは福岡の経済発展に寄与したいということ。せっかく福岡という地に開業するならば、地元企業や団体と組むことでお互いwin-winになれたらなという想いがありました。
                  </>
                ),
              },
              {
                label: "山本",
                text: (
                  <>
                    そういう意味で、地元出身のメンバーが開業に携わっていたことはとても心強かったですね。
                  </>
                ),
              },
              {
                label: "松本",
                text: (
                  <>
                    そうですね。とはいえ、地域との強固なつながりは特になかったので、県や市のHPからSDGsに取り組まれている企業や団体がないかを地道に探していったんですよね。そんな中で出会ったのが今回ラウンジのモチーフや内装のアートをお願いした株式会社ふくしごとです。
                  </>
                ),
              },
            ]}
          />
          <CProfileMedia
            reverse={false}
            image={{
              img: {
                src: "/assets/images/journal/article08/img_chapter02_01.jpg",
              },
              imgSp: {
                src: "/assets/images/journal/article08/img_chapter02_01__sp.png",
              },
            }}
            alt={"ロイヤルパークホテルズ 運営推進部 松本 章子 氏"}
            company={<>ロイヤルパークホテルズ</>}
            officer={<>運営推進部</>}
            name={<>松本 章子</>}
            honor={"氏"}
            text={
              <>
                2008年「横浜ロイヤルパークホテル」に入社。ハウスキーパーやベル、宿泊予約業務などを経験。2020年からセールス＆マーケティング部（現在の運営推進部）にて新規及び既存ホテルの広報を担当。
              </>
            }
          />
          <CQuoteBox>
            <p>―どんな取り組みをされているんですか。</p>
          </CQuoteBox>
          <CLabelTextList
            data={[
              {
                label: "松本",
                text: (
                  <>
                    ふくしごとさんは、福祉施設と連携した企業の人材育成やアーティストとの協働を通じ、新たな視点で社会課題解決と価値創造に取り組む企業さんです。2022年7月にコンタクトを取った後、ホテルのコンセプトについてお話ししたところ、ぜひ何か一緒にやりましょうということで意気投合したんです。
                  </>
                ),
              },
              {
                label: "山本",
                text: (
                  <>
                    コンセプトへの共感というところもそうですが、ふくしごとさんもアーティストの活躍できる場をちょうど探していたようです。
                  </>
                ),
              },
              {
                label: "松本",
                text: (
                  <>
                    当初はホテルのノベルティデザインのみを依頼していたのですが、いただいたデザイン案がとても素敵で。ノベルティだけにとどめるのはもったいない！とさまざまな部署を巻き込んで、最終的には客室のアートからラウンジのモチーフ（壁画）、スタッフが身につけるサコッシュにプリントされているアートまで発展しました。
                  </>
                ),
              },
            ]}
          />
          <CTileImg
            size="small"
            data={[
              {
                img: {
                  src: "/assets/images/journal/article08/img_chapter02_02.jpg",
                  alt: "",
                },
                caption: (
                  <>
                    全客室の中で約80部屋がふくしごとに所属するアーティストによって手掛けられたもの。
                  </>
                ),
              },
              {
                img: {
                  src: "/assets/images/journal/article08/img_chapter02_03.jpg",
                  alt: "",
                },
                caption: (
                  <>
                    九州各地のイラストをコラージュした九州マップ。CANVASラウンジを象徴するモチーフに。
                  </>
                ),
              },
            ]}
          />
          <CLabelTextList
            data={[
              {
                label: "山本",
                text: (
                  <>
                    取り組みをきっかけに、福岡でこんな活動をされている企業があるということをぜひ知ってほしいですよね。
                  </>
                ),
              },
              {
                label: "松本",
                text: (
                  <>
                    もともとCANVASブランドのコンセプトの中に「LOCAL」や「CONNECT」というテーマが入っていることから、出会いのきっかけの場になれたら嬉しいなと思っています。
                  </>
                ),
              },
            ]}
          />
          <CQuoteBox>
            <p>
              ―「出会いの場」ということですと、CANVASラウンジのイベントも特徴的ですよね。
            </p>
          </CQuoteBox>
          <CLabelTextList
            data={[
              {
                label: "山本",
                text: (
                  <>
                    ホテルの象徴的な場所であるCANVASラウンジに旧明治安田生命福岡ビルのホールで使用されていたピアノをお借りして設置しました。そこで地元アーティストの方々による音楽イベントを行なっています。
                  </>
                ),
              },
              {
                label: "松本",
                text: (
                  <>
                    福岡はもともと中洲ジャズなどが有名で、音楽を振興していく意識が強いですよね。
                  </>
                ),
              },
            ]}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article08/img_chapter02_04.jpg",
              alt: "",
            }}
            caption={
              <>
                ホテルのある場所はかつての明治安田生命福岡ビルの跡地。そのご縁もありホールで活躍していたピアノを提供してもらえることに。
              </>
            }
          />
          <CLabelTextList
            data={[
              {
                label: "山本",
                text: (
                  <>
                    ただピアノを活用したイベントとなると弾き手がなかなか見つからず…、そんな時に福岡音楽都市協議会のことを知って。福岡音楽都市協議会は2021年に設立された団体で、音楽の力を使って福岡の街を盛り上げることを目的に、アーティストの支援もされています。先ほどのふくしごとさんと同じく、活動の場を探されていたんです。
                  </>
                ),
              },
            ]}
          />
          <CQuoteBox>
            <p>―CANVASラウンジでの取り組みはどんな内容なのでしょうか。</p>
          </CQuoteBox>
          <CLabelTextList
            data={[
              {
                label: "山本",
                text: (
                  <>
                    毎週金曜にアーティストの方を招いてライブを開催しています。演奏ジャンルに縛りはありませんし、ピアノを使っていただいてもいただかなくてもいいというフリースタイルです。
                  </>
                ),
              },
              {
                label: "松本",
                text: (
                  <>
                    音楽のジャンルを縛らなかった理由も山本さんの想いがあるんですよね。
                  </>
                ),
              },
              {
                label: "山本",
                text: (
                  <>
                    ホテルの名前にもあるとおり「CANVAS」ですから、真っ白な何もないところにアーティストたちが自分色に自由に色を塗って欲しい。あとは訪れたお客様にとっても知らないジャンルの音楽や楽器、演奏方法に出会えるチャンスだと思っているので、あえて制限はせず新しい化学反応が起こるのを楽しめたらなと。
                  </>
                ),
              },
            ]}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article08/img_chapter02_05.jpg",
              alt: "",
            }}
            caption={
              <>
                参加アーティストは福岡音楽都市協議会のキュレーションメディアOTOJIROのデータベース登録者から公募で決定するなど、アーティストにとってもチャンスの場に。
              </>
            }
          />
        </LWrap>
      </section>

      <section className="u_mbLarge">
        <CChapterHeading
          data={{
            label: {
              en: "CHAPTER",
              number: "03",
            },
            title: (
              <>
                <span>客室で体感できる、</span>
                <span>九州の自然と癒し</span>
              </>
            ),
          }}
        />
        <LWrap size="small">
          <CQuoteBox>
            <p>
              ―どのタイプの客室も「自然」や「文化」をテーマに滞在が楽しめますね。
            </p>
          </CQuoteBox>
          <CLabelTextList
            data={[
              {
                label: "山本",
                text: (
                  <>
                    客室はお客様が一番リラックスしてコンセプトも感じていただけるところだと思うので、どんな体験を提供できるのかというところにこだわりました。今回最上階の客室には、福岡県遠賀郡芦屋町の里山で丁寧に育てられた天然素材を使用しているブランド「NEMOHAMO」のシャンプー、トリートメント、ボディソープを採用しています。
                  </>
                ),
              },
            ]}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article08/img_chapter03_01.jpg",
              alt: "",
            }}
            caption={
              <>
                その名の通り「根」も「葉」もあますことなく活用。「九州の自然に癒される」コンセプトそのものを体現しているかのよう。
              </>
            }
          />
          <CLabelTextList
            data={[
              {
                label: "松本",
                text: (
                  <>
                    SDGsに配慮した九州産のアメニティを探していたのですが、なかなかコストやロットなどの障壁も高くて。そんな時、偶然山本さんが見つけてくださったんですよね。
                  </>
                ),
              },
              {
                label: "山本",
                text: (
                  <>
                    TVを観ていたら有名な寝台列車の客室アメニティとして紹介されていて、これはまさにぴったりだなと。他のアメニティやサービスにも通じるのですが、コンセプトや想いの部分が合致するかどうかを重視していたので、まずはご一緒させていただきたいパートナーさんを探してから、どう実現できるか、という具体的な流れに持っていくことはとても大事にしていました。
                  </>
                ),
              },
              {
                label: "松本",
                text: (
                  <>
                    本当にそうですね。そういった視点で議論をしながら進めていけたのはよかったです。
                  </>
                ),
              },
            ]}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article08/img_chapter03_02.jpg",
              alt: "",
            }}
          />
          <CLabelTextList
            data={[
              {
                label: "山本",
                text: (
                  <>
                    実際に香りもとても良くて、お客様へお伝えできるストーリー性もあります。
                  </>
                ),
              },
              {
                label: "松本",
                text: (
                  <>
                    植物成分を使っているので、香りも優しいハーブやナチュラルな印象、ボトルの色合いもジェンダーレスカラーでとても良いです。
                  </>
                ),
              },
              {
                label: "山本",
                text: (
                  <>ぜひ、お部屋の中でも九州の自然に癒されてほしいですね。</>
                ),
              },
            ]}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article08/img_chapter03_03.jpg",
              alt: "",
            }}
          />
          <CQuoteBox>
            <p>
              ―そのほかのアメニティについてもSDGsへの意識があると思うのですが。
            </p>
          </CQuoteBox>
          <CLabelTextList
            data={[
              {
                label: "松本",
                text: (
                  <>
                    学校やレストランから回収した不要食器を粉砕した素材を混ぜ込んだマグカップや、製造工程で出てくるチップを再利用したティッシュボックスやトレーなど、客室内の細かなところにもSDGsのエッセンスを散りばめています。
                  </>
                ),
              },
            ]}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article08/img_chapter03_04.jpg",
              alt: "",
            }}
          />
          <CLabelTextList
            data={[
              {
                label: "山本",
                text: (
                  <>
                    これらのアメニティについては、福岡の現地スタッフから取り入れたいという声が上がりました。ホテルのコンセプトをもとに、“みんなで作る”というところもSDGsの理念につながっていると思います。
                  </>
                ),
              },
            ]}
          />
        </LWrap>
      </section>

      <section className="u_mbLarge">
        <CChapterHeading
          data={{
            label: {
              en: "CHAPTER",
              number: "04",
            },
            title: (
              <>
                <span>地元とつながり、</span>
                <span>可能性はさらに広がっていく</span>
              </>
            ),
          }}
        />
        <LWrap size="small">
          <CQuoteBox>
            <p>―食については、いかがでしょうか？</p>
          </CQuoteBox>
          <CLabelTextList
            data={[
              {
                label: "松本",
                text: (
                  <>
                    ホテル内の湯上りラウンジはお客様がチェックインからチェックアウトまで自由にご利用いただけるスペースです。そこでご提供するコーヒーは福岡の名店「REC
                    COFFEE」のものです。
                  </>
                ),
              },
            ]}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article08/img_chapter04_01.jpg",
              alt: "",
            }}
            caption={
              <>
                全面窓ガラスで、ルーフトップガーデンが一望できる開放感溢れる空間。
              </>
            }
          />
          <CLabelTextList
            data={[
              {
                label: "山本",
                text: <>これは、福岡出身のメンバーからの発案でした。</>,
              },
              {
                label: "松本",
                text: (
                  <>
                    REC COFFEEといえば、昔から福岡で
                    “あの有名なコーヒートラック”として知られています。福岡出身のメンバーが学生のときから何度も利用していたお店と話していて、地元の人にとっては馴染みのあるお店なんじゃないでしょうか。今や福岡県内外に複数店舗展開されていますし、もしかしたらと思い、問合せをしてみたところ自家焙煎もされているということで、コラボレーションが実現したんですよね。
                  </>
                ),
              },
              {
                label: "山本",
                text: (
                  <>
                    何よりも地元に愛されている企業というところが良いですよね。私もREC
                    COFFEEの方とお話をさせていただいた際に、とても印象が良くて。
                  </>
                ),
              },
              {
                label: "松本",
                text: (
                  <>
                    ホテルへの豆の卸も初の試みということだったのですが、ぜひ何かご一緒したいそんな話で盛り上がりました。
                  </>
                ),
              },
            ]}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article08/img_chapter04_02.jpg",
              alt: "",
            }}
          />
          <CLabelTextList
            data={[
              {
                label: "松本",
                text: <>焙煎工場の見学にも実際に行かれたんですよね。</>,
              },
              {
                label: "山本",
                text: (
                  <>
                    味もさることながら、店舗の方々も工場の方々も、みなさんとてもイキイキと働かれていて、すっかりファンになりましたね(笑)改めて地元に愛される企業のパワーや熱意などを感じて、とても勉強になりました。
                  </>
                ),
              },
              {
                label: "松本",
                text: <>それは私も行きたかったな。</>,
              },
              {
                label: "山本",
                text: (
                  <>
                    取り組みだけではなく、ホテルの運営や社員教育という視点でも参考になることばかりでした。ぜひ湯上りラウンジでREC
                    COFFEEさんの味を楽しんでいただきたいですね。
                  </>
                ),
              },
            ]}
          />
          <CQuoteBox>
            <p>―湯上りラウンジで提供しているアイスにもこだわりがあるとか。</p>
          </CQuoteBox>
          <CLabelTextList
            data={[
              {
                label: "松本",
                text: (
                  <>
                    はい。ホテル内のレストラン「ONO HAKATA－Octo Natura
                    Kyusyu－」を運営するONOグループさんにご協力いただき、ラウンジで自由にお召しあがりいただけるアイスを考案してもらいました。
                  </>
                ),
              },
              {
                label: "山本",
                text: (
                  <>
                    当初は福岡なのであまおうのいちごアイスやアイスクリームマシーンなどアイデアがあったのですが、なかなかオペレーション部分でクリアできず…。
                  </>
                ),
              },
              {
                label: "松本",
                text: (
                  <>
                    もともとONOグループさんはいろいろなコネクションをお持ちで、地元農家さんなどともつながりが深いです。そんな中で販売できない規格外のフルーツをアイスにすることで、フードロス削減に貢献できるとアイデアをいただきました。
                  </>
                ),
              },
              {
                label: "山本",
                text: (
                  <>
                    私たちとしてもSDGsを軸にしているため、食への取り組みは欠かせないところ。地元農家さんへ貢献できるとあって願ってもないチャンスでした。
                  </>
                ),
              },
              {
                label: "松本",
                text: (
                  <>
                    フードロス削減と言ってしまうと、大それたことのように感じますが、湯上りラウンジでアイスを食べる、そんな小さな体験から知らぬ間にフードロス削減へ貢献できるというのもお客様にとって良いきっかけになるなと考えたんです。
                  </>
                ),
              },
              {
                label: "山本",
                text: (
                  <>
                    ホテルが今まで提供できなかった、新しい体験・価値ですよね。
                  </>
                ),
              },
            ]}
          />
          <CTileImg
            size="small"
            data={[
              {
                img: {
                  src: "/assets/images/journal/article08/img_chapter04_03.jpg",
                  alt: "",
                },
              },
              {
                img: {
                  src: "/assets/images/journal/article08/img_chapter04_04.jpg",
                  alt: "",
                },
              },
            ]}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article08/img_chapter04_05.jpg",
              alt: "",
            }}
            caption={
              <>
                規格外のフルーツを使用した「CANVASフルーツアイスバー」。すべてレストラン内で作られている。
              </>
            }
          />
          <CQuoteBox>
            <p>
              ―今回地元企業への貢献というところもこだわっていますが、その理由はなぜでしょうか。
            </p>
          </CQuoteBox>
          <CLabelTextList
            data={[
              {
                label: "松本",
                text: (
                  <>
                    地元企業とつながることによって、そこからまたどんどんコネクションが広がっていく可能性を感じています。
                  </>
                ),
              },
              {
                label: "山本",
                text: (
                  <>
                    福岡は、人も企業もみなさんウェルカムに受け入れてくださる方が多いので、ありがたいです。ONOグループさんしかり、地元を大切にしたいという考えがとても強いのでコンセプトにも共感をいただいているんだなと。
                  </>
                ),
              },
              {
                label: "松本",
                text: (
                  <>
                    我々ロイヤルパークホテルズのコンセプトでもある「街と、もてなす。」にも通じるところがありますが、地元の方々とつながることでさらにそれを後押ししていただけるかなと。特に宿泊主体型のホテルは客室で休むこと以外のおもてなしがなかなか難しいです。だからこそ、その街のリソースを活用させていただき、おもてなししていくという視点が大事なのかなと。
                  </>
                ),
              },
            ]}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article08/img_chapter04_06.jpg",
              alt: "",
            }}
          />
          <CLabelTextList
            data={[
              {
                label: "山本",
                text: (
                  <>
                    開業はゴールではなくスタートだと思っていますので、今後も地元企業様にとって「一緒に何かしたい」と思っていただけるようなホテルでありたいです。また同時にここで働くスタッフにとっても誇りに思ってもらえるようなそんなホテルに育てていきたいです。
                  </>
                ),
              },
              {
                label: "松本",
                text: (
                  <>
                    継続的に福岡の街に貢献できるようにしていきたいですね。今回の開業を機に出会えたパートナー企業様のご縁を大切にして、双方がwin-winになれるような将来を見据えていきたいです。
                  </>
                ),
              },
            ]}
          />
        </LWrap>
      </section>
      <section className="l_sect u_pt0">
        <LWrap size="small">
          <CSingleImg
            img={{
              src: "/assets/images/journal/article08/img_chapter05_01.jpg",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    ホテルという場を起点に、さまざまなつながりや企画を生み出すその原点には“人”がある。地元の人と素材とともに育む、ザ
                    ロイヤルパーク キャンバス
                    福岡中洲の開業に込められた想いからホテルの新しいあり方、可能性が見えてきた。
                    <br />
                    そんな背景も含めて「九州の自然に癒される」体験をしに、ぜひ訪れてみてほしい。
                  </p>
                </>
              ),
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  <ul className="c_noteList">
                    <li>本記事・画像は2023年8月時点のものです。</li>
                  </ul>
                </>
              ),
            }}
          />
          
          <CJournalText
            exClass="u_tac u_mb20"
            data={{
              text: (
                <>
                  <h3 className="c_headingLv3 u_mb0">
                    ザ ロイヤルパーク キャンバス 福岡中洲 おすすめプラン
                  </h3>
                  <p>「九州の自然に癒される」ホテル滞在を楽しみませんか？</p>
                </>
              ),
            }}
          />
          <CQuoteBox>
            <h3 className="c_headingLv3 u_fwb">
              ＜最上階確約＞LUXURY CANVAS STAY 【朝食付】
            </h3>
            <p>
              10F以上の高層階フロア「スカイフロア」のうち、最上階となる14F確約のプラン。プレミアムツインリバーサイドやコーナーデラックスツインなど各種部屋タイプをご用意。朝食も付いています。
            </p>
            <CBtnList
              exClass="u_mt20"
              data={[
                {
                  label: "プラン詳細",
                  color: "bgBlack",
                  link: {
                    href: "http://rsv.ihonex.com/cgi-bin/ihonex3/plan_shokai.cgi?hid=rphs_cfukuokanakasu&plan_groupcd=H05B&form=jp",
                    blank: true,
                  },
                  icon: "blank",
                },
              ]}
            />
          </CQuoteBox>

          <p className="u_fwb u_tac u_mt80">ザ ロイヤルパーク キャンバス 福岡中洲</p>
          <CBtnList
            exClass="u_mt20"
            data={[
              {
                label: "ホテルサイト",
                color: "bgBlack",
                link: {
                  href: "https://www.royalparkhotels.co.jp/canvas/fukuokanakasu/",
                  blank: true,
                },
                icon: "blank",
              },
            ]}
          />
        </LWrap>
      </section>

      <div className="l_sect02 u_bgGray">
        <LWrap>
          <CBtnList
            data={[
              {
                label: "ロイヤルパークホテルズJournal",
                link: {
                  href: "/pickup/#journal",
                },
                iconBefore: true,
                icon: "arrowReverse",
              },
            ]}
          />
        </LWrap>
      </div>
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
